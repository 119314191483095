<template>
  <v-navigation-drawer v-model="drawer" temporary absolute>
    <header aria-label="Drawer" class="d-flex">
      <v-btn
        fab
        text
        class="v-navigation-drawer__content-close"
        @click="toggleDrawer"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </header>
    <div>
      <v-tabs v-model="tab" vertical>
        <v-tab
          v-for="(item, index) in items"
          :key="index"
          :value="item"
          :to="item.to"
          class="text-capitalize font-weight-black text-left"
        >
          <v-badge
            v-if="item.title == 'Opportunities'"
            inline
            color="#ff3e43"
            :content="opportunityCount"
            :value="opportunityCount"
          >
            {{ item.title }}
          </v-badge>
          <span v-else> {{ item.title }} </span>
        </v-tab>
      </v-tabs>
      <v-divider class="divider" />
      <v-list dense>
        <v-list-item v-if="beamer">
          <span class="beamer-announcements"> Announcements </span>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <router-link to="/account">
              <v-badge
                inline
                color="#ff3e43"
                :content="feedbackCount"
                :value="feedbackCount"
              >
                <span class="mr-1 account">Account</span>
              </v-badge>
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <logout class="signout text-left" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { opportunityHelpers as opps } from '@/store/opportunities'
import { feedbackHelpers as feedback } from '@/store/feedback'
import { appHelpers as app } from '@/store/app'
import Logout from '@/components/Auth/Logout.vue'

export default {
  name: 'Drawer',
  components: {
    Logout,
  },
  props: {
    toggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabVal: null,
      drawerVal: false,
      items: [
        {
          title: 'Consults',
          to: '/dashboard',
        },
        {
          title: 'Schedule',
          to: '/schedule',
        },
        {
          title: 'Earnings',
          to: '/earnings',
        },
        {
          title: 'Opportunities',
          to: '/opportunities',
        },
        {
          title: 'Education',
          to: '/education',
        },
      ],
    }
  },
  computed: {
    opportunityCount: opps.mappedGetters.newCount || false,
    feedbackCount: feedback.mappedGetters.unresolvedCount || false,
    announcements: app.mappedGetters.announcements,
    drawer: {
      get() {
        return this.drawerVal
      },
      set(val) {
        this.drawerVal = val
      },
    },
    tab: {
      get() {
        return this.tabVal === null ? true : this.tabVal
      },
      set(val) {
        this.tabVal = val
      },
    },
    beamer() {
      return this.$beamer.enabled
    },
  },
  watch: {
    toggle() {
      this.drawerVal = this.toggle
    },
    $route() {
      this.closeDrawer()
    },
  },
  methods: {
    toggleDrawer: app.mappedActions.toggleDrawer,
    closeDrawer: app.mappedActions.closeDrawer,
  },
}
</script>

<style lang="scss" scoped>
v-navigation-drawer {
  &-close {
    margin-left: auto;
    align-self: flex-end;
  }
}

.v-tab {
  justify-content: left;
  letter-spacing: 1px;
}

.v-tab--active {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 0.4em;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #000000;
}

::v-deep .v-tabs-slider {
  display: none;
}

a {
  text-decoration: none;
  color: $navy;
}

.divider {
  margin-left: 16px;
  margin-right: 16px;
}

.account {
  color: #000000;
}
</style>
