/**
 * Assignment Model
 * @typedef {Object} assignment
 * @property {number} id
 * @property {string} notification_hash
 * @property {date} created_at
 * @property {string} accept_route
 * @property {number} age
 * @property {number} lifetime
 * @property {date|null} expires_at
 * @property {string} decline_route
 * @property {string} status
 * @property {Object} consult - The Consult associated with the assignment
 * @property {number} consult.id
 * @property {string} consult.state
 * @property {string} consult.status
 * @property {Object} consult.client
 * @property {string} consult.client.name
 * @property {Object} consult.appointment
 * @property {date} consult.appointment.buffer_start_at
 * @property {date} consult.appointment.scheduled_start_at
 * @property {date} consult.appointment.estimated_completed_at
 * @property {date} consult.appointment.buffer_end_at
 * @property {date} consult.appointment.patient_no_show_at
 * @property {Object} consult.consult_rate
 * @property {string} consult.consult_rate.display_name
 * @property {number} consult.consult_rate.paid_to_provider
 * @property {string} consult.consult_rate.modality
 * @property {string} consult.consult_rate.modality_type
 */

/**
 * Vue store for assignments
 * @typedef {Object} assignments
 * @property {assignment[]} data - Collection of assignments
 * @property {assignment[]} readyForResponse  - Collection of ready for response assignments
 * @property {number} deferredCount - Number of assignments for deferred consults
 * @property {number} heldCount - Number of assignments for held consults
 * @property {consult[]} upcoming - Collection of consults with upcoming bookings
 * @property {(string|number)} current - Identifier for the currently visible assignment
 */

export const NAMESPACE = 'assignments'

export default () => ({
  data: [],
  readyForResponse: [],
  deferredCount: 0,
  heldCount: 0,
  flaggedAssignment: { assignment: null, alert: false, show: false },
  upcoming: [],
})
